import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/dumbo.jpeg";
import "animate.css";
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="allign-items-center">
          <Col xs={12} md={6} xl={7}>
          <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7 }}
      className="transition ease-in-out">
      <main className="px-4">
        <section className="pb-10">
          <h1 className="mb-4 text-4xl font-semibold text-white">
            <span style={{ marginRight: "0.3em" }}>Hi, I am</span>
            <span style={{ color: '#64ffda' }}>
              <TypeAnimation
                sequence={[
                  "Win",
                  2000,
                  " a developer",
                  1000,
                  " a student @ Northeastern",
                  2000,
                  " a gamer",
                  1000,
                  " searching for Fall 2024 Positions",
                  2000,
                ]}
                wrapper="span"
                speed={25}
                style={{ fontSize: "1em", display: "inline-block" }}
                repeat={Infinity}
              />{" "}
              <br />
            </span>
            <span className="text-white/60">
              Passionate about building things.
            </span>
          </h1>
          <p className="text-base leading-6 text-white/70">
          Hi, I'm Win. I'm a junior at Northeastern University, majoring in Computer Science, with a concentraion in Software Development.
          Outside of school, I'm a project lead for FirstByte, a club focused on expanding accessibility to STEM.
          </p>
          <p className="text-base leading-6 text-white/70">
          Currently exploring machine learning, and iOS/visionOS app development.
          </p>
          <p className="text-base leading-6 text-white/70">
          Looking for Fall 2024 Co-ops/Internships.
          </p>
        </section>
      </main>
    </motion.div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img className = "banner-img" src={headerImg} alt="Header Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
